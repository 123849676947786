@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 111%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }

  
}

#p-header {
  font-weight: bold;
  font-size: 1.5em;
}


.center-elements {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

#features img {
  width: 250px;
}

h3 {
  font-weight: bold;
  font-size: 30px;
  padding: 30px;
}